// For light use only
(function() {
  var permission = checkCookiePermission();
  if (!permission) {
    document.getElementById("acceptCookieButton").addEventListener('click', acceptCookies);
  } else {
    
  }
  
})();

function checkCookiePermission() {
  var cookiesAllowed = accessCookie('CookiesAllowed');
  if (cookiesAllowed !== "") {
    console.log("Cookies accepted");
    return true;
  } else {
    console.log("Cookies not yet accepted");
    askPermission();
    return false;
  }
}

function accessCookie(cookieName) {
  var name = cookieName + "=";
  var allCookieArray = document.cookie.split(';');
  for (var i = 0; i < allCookieArray.length; i++) {
    var temp = allCookieArray[i].trim();
    if (temp.indexOf(name) == 0)
      return temp.substring(name.length, temp.length);
  }
  return "";
}

function askPermission() {
  var permissionDiv = `<div style="" class="perm pb-3 pt-1 pl-2">Hei! Käytämme evästeitä analytiikan ja paremman käyttökokemuksen parantamiseksi. <button id="acceptCookieButton">Ok</button></div>`;
  var docBody = document.getElementById("cookieBanner");
  docBody.insertAdjacentHTML('beforeend', permissionDiv);
}

function acceptCookies() {
  console.log("cookie added");
  var d = new Date();
  d.setTime(d.getTime() + (30*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = "CookiesAllowed=true; " + expires + ";";
  document.getElementById("cookieBanner").style.display = "none";
}